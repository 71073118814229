img {
	width: 100%;
	height: auto;
	max-width: 100%;
}

.image-with-caption {
	img {
		margin-bottom: 0;
		display: block;
	}
	.caption {
		margin-bottom: 0 !important;
		margin-top: $spacing-small !important;
		font-style: italic;
	}
}

.page-image {
	margin-bottom: $spacing-medium + $spacing-small;
	margin-left: $spacing-small;
	width: calc(100% - #{$spacing-medium});

	@media (min-width: $breakpoint-desktop) {
		padding: 0 60px;
	}

	&--article {
		margin-bottom: $spacing-small;
	}
}
