// View fade
.fade-enter {
    opacity: 0.01;
    transform: translateY(20px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.35s ease-in-out;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0.01;
    transform: translateY(20px);
    transition: all 0s;
}

// Card fade in out
.fade-in-out-enter {
    opacity: 0.01;
    transform: translateY(20px);
}

.fade-in-out-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.35s ease-in-out;
}

.fade-in-out-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-in-out-exit-active {
    opacity: 0.01;
    transform: translateY(20px);
    transition: all 0.35s ease-in-out;
}