// Must have Bootstrap Sass
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Fonts
@font-face {
  font-family: "Absolution";
  src: url(../fonts/Absolution-mix.otf) format("opentype");
}

@font-face {
  font-family: "Absolution-0";
  src: url(../fonts/Absolution-style-0.otf) format("opentype");
}

@font-face {
  font-family: "Absolution-2";
  src: url(../fonts/Absolution-style-2.otf) format("opentype");
}

@font-face {
  font-family: "Finlandica";
  src: url(../fonts/Finlandica-regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Finlandica";
  src: url(../fonts/Finlandica-italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Finlandica";
  src: url(../fonts/Finlandica-bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Finlandica";
  src: url(../fonts/Finlandica-bold-italic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}

// Settings
$grid-gutter-width: 32px;
$black: #000000;
$white: #ffffff;
$green: #2d6768;
$yellow: #847539;
$blue: #1947a7;
$brightblue: #afccfc;
$lightblue: #a0c8c8;
$dark-blue: #010658;
$light-grey: #dddddd;
$bright-grey: #eeeeee;
$grey1: #bbbbbb;
$grey: #757575;
$spacing-tiny: 8px;
$spacing-small: $spacing-tiny * 2;
$spacing-medium: $spacing-tiny * 4;
$spacing-large: $spacing-tiny * 6;
$spacing-xlarge: $spacing-tiny * 8;
$body-font-size-mobile: 16px;
$body-font-size-desktop: 18px;
$button-font-size: 18px;
$small-title: 36px;
$smaller-title: 32px;
$info-text: 20px;
$tiny-title: 28px;
$title-font-size: 48px;
$title-margin-bottom: $spacing-small;
$button-margin-bottom: $spacing-small;
$paragrah-margin-bottom: $spacing-small;
$breakpoint-desktop: 1168px;
$breakpoint-tablet: 991px;
$breakpoint-mobile: 430px;

// Components
@import "./components/app.scss";
@import "./components/animations.scss";
@import "./components/document.scss";
@import "./components/typography.scss";
@import "./components/footer.scss";
@import "./components/buttons.scss";
@import "./components/pagination.scss";
@import "./components/images.scss";
@import "./components/embed.scss";
@import "./components/navigation.scss";
@import "./components/grids.scss";
@import "./components/header.scss";
@import "./components/persons.scss";
@import "./components/social-icons.scss";
@import "./components/search.scss";
@import "./components/slices.scss";
@import "./components/newsletter.scss";
@import "./components/events.scss";
@import "./components/hero.scss";
@import "./components/analytics.scss";
@import "./components/about.scss";
@import "./components/subscribe.scss";
