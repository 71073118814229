.container {
  @include make-container($gutter: 0);
  max-width: 1200px;

  @media (min-width: $breakpoint-desktop) {
    padding: 0 60px;
  }
}

.container-fluid {
  width: 90%;
  padding: 0;
}

.narrow-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  &__item {
    @include make-col-ready();
    @include make-col(12);
    margin-bottom: $spacing-medium;

    @media (min-width: $breakpoint-desktop) {
      @include make-col(6);
      margin-bottom: $spacing-medium;
    }

    &.item--full-width {
      @include make-col(12);
    }

    &.item--third-width {
      @media (min-width: $breakpoint-desktop) {
        @include make-col(4);
      }
    }

    &.item--text {
      @include make-col(8);
    }
  }
}

.masonry-grid {
  display: flex;
  padding: 0;
  width: 100%;
  position: relative;

  .masonry-grid__column,
  .masonry-grid__column_no_padding {
    position: relative;
    background-clip: padding-box;

    &:nth-child(2) {
      padding-left: $spacing-medium; // gutter size
    }
    &:nth-child(3) {
      padding-left: $spacing-medium; // gutter size
    }

    > .masonry-grid__item {
      position: relative;
      margin-bottom: $spacing-large;
      overflow-wrap: break-word;
      hyphens: auto;
      animation: fade 0.5s ease-in-out;

      @keyframes fade {
        from {
          transform: translateY(20px);
          opacity: 0;
        }
        to {
          transform: translateY(0px);
          opacity: 1;
        }
      }

      &.item--text-area {
        padding-top: $spacing-large;

        h1,
        h2 {
          text-align: left;
          font-size: 32px;
          line-height: 1;

          @media (min-width: $breakpoint-desktop) {
            font-size: 48px;
          }
        }

        p,
        .button-container {
          text-align: left;

          .button {
            margin-top: $spacing-small;
            margin-right: 0;
          }
        }
      }

      .item__meta,
      .typewriter-container,
      .read-more,
      .text-area {
        position: relative;
      }
      .excerpt {
        position: relative;
        line-height: 1.5em;
      }

      .item__meta {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .tag a {
          margin-bottom: $spacing-small;
        }
      }

      a,
      p,
      h2 {
        margin-bottom: $spacing-small;
      }

      h2,
      .text-area {
        text-align: left;
      }

      svg {
        position: absolute;
        pointer-events: none;
      }

      .item__image {
        background-size: cover;
        background-position: center;
        background-color: $bright-grey;
      }

      h3.title {
        font-size: 22px;
        font-family: "Absolution-0";

        @media (min-width: $breakpoint-desktop) {
          font-size: 32px;
        }
      }

      &.item--social-links {
        padding-top: $spacing-large;

        h1,
        h2 {
          text-align: left;
          font-size: 22px;
          line-height: 1;

          @media (min-width: $breakpoint-desktop) {
            font-size: 32px;
          }
        }

        .follow {
          padding: 0;

          &__top-row,
          &__bottom-row {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            a {
              display: inline-block;
              position: relative;
              width: 40px;
              height: 40px;

              &:not(:last-of-type) {
                margin-right: $spacing-small;
              }

              @media (min-width: $breakpoint-desktop) {
                float: right;
              }
            }
          }
        }
      }

      &.item--newsletter {
        padding-top: $spacing-large;

        h1,
        h2 {
          font-size: 22px;
          text-align: left;
          line-height: 1;

          @media (min-width: $breakpoint-desktop) {
            font-size: 32px;
          }
        }

        p,
        .button-container {
          text-align: left;
          padding: 0;

          .button {
            margin-top: $spacing-small;
            margin-right: 0;
          }
        }
      }
    }
  }
  .masonry-grid__column_no_padding {
    &:nth-child(2) {
      padding-left: 0;
    }
    &:nth-child(3) {
      padding-left: 0;
    }
  }
}

.events-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: relative;

  &__item {
    position: relative;
    @include make-col-ready();
    @include make-col(12);
    margin-bottom: $spacing-medium;
    display: flex;
    flex-direction: row;
    animation: fade 0.5s ease-in-out;

    @keyframes fade {
      from {
        transform: translateY(20px);
        opacity: 0;
      }
      to {
        transform: translateY(0px);
        opacity: 1;
      }
    }

    @media (min-width: $breakpoint-desktop) {
      margin-bottom: $spacing-small;
    }

    .item-image {
      width: 20%;
      padding-right: $spacing-small;

      @media (min-width: $breakpoint-desktop) {
        width: 20%;
      }

      div {
        width: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $bright-grey;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }

    .item-content {
      width: 80%;

      @media (min-width: $breakpoint-desktop) {
        padding-top: $spacing-tiny;
      }

      .meta p {
        display: inline-block;
        margin-bottom: $spacing-small;

        &:not(:last-of-type) {
          margin-right: $spacing-tiny;
        }
      }

      h2 {
        margin-bottom: $spacing-small;
      }
    }
  }
}
