.embed-container { 
    position: relative; 
    width: 100%;
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}
