html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  &.nav-open {
    overflow: hidden;
    position: relative;
    height: 100%;

    .document {
      pointer-events: none;
      z-index: -1;
    }
  }
}

.maintenance-mode {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 11111111111;
  pointer-events: none;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.is-active {
    background-color: $white;
    opacity: 1;
    pointer-events: all;
  }

  h2 {
    font-family: "Absolution-0";
  }

  h2,
  p {
    max-width: 420px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__input-wrap {
    position: relative;
    display: flex;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: $black;
    }
  }

  label {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }

  .password {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 8px 0;
    font-family: "Absolution";
    font-weight: normal;
    color: $black;
    font-size: 22px;
    line-height: 1;
    width: 150px;
    margin-bottom: -1px;

    @media (max-width: $breakpoint-desktop) {
      font-size: 28px;
      width: 200px;
    }

    &::placeholder {
      color: $black;
    }
  }

  .button {
    margin: 0;
    border-bottom: none;
  }

  .error-message {
    position: absolute;
    top: calc(50% + 170px);
    color: #ea6262;

    @media (min-width: $breakpoint-desktop) {
      top: calc(50% + 240px);
    }
  }
}

.CookieConsent {
  background-color: $white !important;
  border-top: 1px solid $black;
  position: fixed;
  width: 100%;
  z-index: 99;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  z-index: 11111111;

  @media (min-width: $breakpoint-desktop) {
    width: calc(100% - 144px);
    flex-direction: row;
    justify-content: space-between;
  }

  div:first-of-type {
    color: $black;
    font-family: "Finlandica";
    padding: $spacing-small;
    margin: 0;
    font-size: $body-font-size-mobile;
    align-items: center;

    @media (max-width: $breakpoint-desktop) {
      width: 100%;
      padding-bottom: 0;
    }

    @media (min-width: $breakpoint-desktop) {
      display: inline-flex;
      font-size: $body-font-size-desktop;
    }
  }

  div:last-of-type {
    padding: $spacing-small;

    @media (max-width: $breakpoint-desktop) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    @media (min-width: $breakpoint-desktop) {
      display: inline-flex;
      justify-content: flex-end;
      flex-direction: row;
      flex-shrink: 0;
    }

    button {
      margin: 0;
      color: $black;
      text-decoration: none;
      padding: 4px $spacing-tiny;
      transition: all 0.35s ease-in-out;
      background-color: transparent;
      font-size: 16px;

      @media (max-width: 1391) {
        font-size: 28px;
      }

      &:hover {
        cursor: pointer;
      }

      &:first-of-type {
        border: none;
      }

      &:last-of-type {
        border: 1px solid $black;

        &:hover {
          background-color: $black;
          color: $white;
        }
      }

      &:not(:last-of-type) {
        margin-right: $spacing-small;
      }
    }
  }
}

.sr-only {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  user-select: none;
}
