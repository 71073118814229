.analytics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 32px;

  @media (min-width: $breakpoint-tablet) {
    margin-top: 100px;
  }

  .analytics_title {
    font-size: $smaller-title;
    margin-bottom: 52px;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
      font-size: $title-font-size;
    }
  }
}

.analytics_cards_container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    gap: 0px;
  }
}

.analytics_card {
  display: flex;
  flex-basis: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  padding: 0 20px 50px 20px;
  align-items: center;

  @media (min-width: $breakpoint-tablet) {
    align-items: flex-start;
  }

  &.first {
    border-left: 1px solid black;
    border-right: 1px solid black;

    @media (min-width: $breakpoint-tablet) {
      border-left: 1px solid black;
      border-right: none;
    }
  }
  &.middle {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
  &.last {
    border-left: 1px solid black;
    border-right: 1px solid black;

    @media (min-width: $breakpoint-tablet) {
      border-left: none;
      border-right: 1px solid black;
    }
  }
}

.analytics_text {
  font-family: "Absolution-0";
  font-size: $info-text;
}

.analytics_figure {
  color: $black;
  font-size: 100px;
  font-family: "Absolution-0";
}
