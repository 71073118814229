.events_title {
  font-family: "Absolution";
  font-size: $smaller-title;
  margin-bottom: 52px;

  @media (min-width: $breakpoint-tablet) {
    font-size: $title-font-size;
  }
}
.events_separator {
  height: 1px;
  width: 90%;
  background-color: $black;
  margin-top: -30px;
  margin-bottom: 52px;
  @media (max-width: $breakpoint-tablet) {
    margin-top: 52px;
  }
}
.event-filters {
  padding: 0 $spacing-small;
  margin-bottom: $spacing-large;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }

  h3 {
    margin-bottom: $spacing-tiny;

    &#tag-message {
      margin-bottom: $spacing-small;
    }
  }

  &__tags {
    position: relative;

    @media (min-width: $breakpoint-desktop) {
      padding-left: $spacing-medium;
    }

    ul {
      list-style-type: none;
      position: relative;
      padding: 0;
      margin: 0;

      li {
        animation: fade 0.5s ease-in-out;
        display: inline-block;
        position: relative;
        line-height: normal;

        @keyframes fade {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }

        input {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
          overflow: hidden;
        }

        label {
          position: relative;
          color: $black;
          margin-bottom: $spacing-tiny;
          text-decoration: none;
          margin-right: $spacing-tiny;
          border: 1px solid $black;
          padding: 4px $spacing-tiny;
          transition: all 0.35s ease-in-out;
          background-color: transparent;
          font-size: 16px;

          &:hover {
            background-color: lighten($black, 90%);
            cursor: pointer;
          }

          &.is-active {
            background-color: $black;
            color: $white;
          }
        }
      }
    }
  }

  &__dates {
    position: relative;
    margin-bottom: $spacing-medium;

    @media (min-width: $breakpoint-desktop) {
      padding-right: $spacing-medium;
      margin-bottom: 0;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: $black;
      }
    }

    .date-start {
      position: relative;
      display: inline-block;
      margin-right: $spacing-small;

      label {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        pointer-events: none;
        overflow: hidden;
      }

      input {
        border: none;
        border-bottom: 1px solid $black;
        padding: 12px 28px 12px 0;
        height: 20px;
        font-size: 16px;
        font-family: "Finlandica";
        max-width: 120px;
        outline: none !important;
        border-radius: 0;

        &::placeholder {
          color: $black;
          font-size: 16px;
          font-family: "Finlandica";
        }
      }
    }

    .date-end {
      position: relative;
      display: inline-block;

      label {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        pointer-events: none;
        overflow: hidden;
      }

      input {
        border: none;
        border-bottom: 1px solid $black;
        padding: 12px 28px 12px 0;
        height: 20px;
        font-size: 16px;
        font-family: "Finlandica";
        max-width: 120px;
        outline: none !important;
        border-radius: 0;

        &::placeholder {
          color: $black;
          font-size: 16px;
          font-family: "Finlandica";
        }
      }
    }

    .divider {
      position: relative;
      display: inline-block;
      margin-right: $spacing-small;

      ion-icon {
        transform: translateY(6px);
      }
    }

    .date-clear {
      right: 0;
      position: absolute;
      border: none;
      padding: 0;
      background-color: transparent;
      opacity: 0;
      pointer-events: none;
      transition: all 0.35s ease-in-out;
      transform: translateY(5px);
      top: -2px;
      cursor: pointer;
      outline: none !important;

      &.is-active {
        transform: translateY(0);
        pointer-events: all;
        opacity: 1;
      }
    }

    .react-datepicker {
      border-radius: 0;
      border-color: $black;
      font-family: "Finlandica";
      font-size: 16px;

      &__triangle {
        display: none;
      }

      &__header {
        border-radius: 0;
        background-color: $white;
        border-bottom: 1px solid $black;
      }

      &__navigation--previous {
        border-right-color: $black;
        outline: none;

        &:hover {
          border-right-color: $black;
        }
      }

      &__navigation--next {
        border-left-color: $black;
        outline: none;

        &:hover {
          border-left-color: $black;
        }
      }

      &__month {
        margin: 0;
        padding: 8px;
      }

      &__day {
        padding: 0;
        margin: 0;
        line-height: 1;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 0;
        border: 1px solid transparent;
        outline: none !important;

        &:hover {
          border-radius: 0;
          border: 1px solid $black;
          background-color: transparent;
        }
      }

      &__day--today {
        background-color: transparent;
        color: $black;
        border: 1px solid transparent;
      }

      &__day--selected,
      &__day--in-range {
        background-color: $black !important;
        color: $white !important;
        border: 1px solid $black !important;
      }

      &__day--keyboard-selected {
        background-color: transparent;
        color: $black;
      }

      &__day--disabled {
        color: $black;
        text-decoration: line-through;

        &:hover {
          background-color: transparent;
          border-radius: 0;
          border: 1px solid transparent;
        }
      }
    }
  }
}

.events__show-more {
  margin-left: $spacing-small;
  animation: fade 0.5s ease-in-out;

  @keyframes fade {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
.search-results-grid__item {
  .item-content {
    padding-top: 0;

    .excerpt {
      margin-bottom: $spacing-tiny;
    }
  }
}

.icon-wrap {
  display: flex;
  flex-direction: row;
  margin-top: $spacing-tiny;
  margin-bottom: $spacing-tiny;

  & + .icon-wrap {
    margin-top: 0 !important;
  }

  ion-icon {
    margin-right: $spacing-tiny;
    flex-shrink: 0;
  }

  p {
    transform: translateY(1px);
  }
}
