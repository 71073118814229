.footer {
  &__top,
  &__bottom {
    position: relative;
    padding-bottom: -20px;

    @media (min-width: $breakpoint-desktop) {
      width: calc(100% - 144px);
      margin-left: 72px;
    }

    .narrow-grid {
      padding-top: $spacing-medium;
      @media (max-width: $breakpoint-mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__item {
        margin-bottom: $spacing-small;

        .logo-wrap {
          margin-top: -4px;
          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }
      .item--full-width {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $breakpoint-mobile) {
          align-self: center;
        }
      }

      .item--social-links {
        a {
          display: inline-block;

          &:not(:last-of-type) {
            margin-right: $spacing-small;
          }
        }
        @media (min-width: $breakpoint-desktop) {
        }
      }
    }

    p {
      margin-bottom: $spacing-small;
    }
  }

  &__top {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding-bottom: $spacing-small;
  }
}
