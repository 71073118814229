.person {
	display: block;
	position: relative;

	.person__content {
		display: flex;
		flex-direction: row;

		.person__left {
			width: 30%;
			max-width: 80px;
			position: relative;
			margin-right: $spacing-small;

			.person__image {
				background-size: cover;
				background-position: center top;
				background-color: $light-grey;
				width: 100%;
				position: relative;

				&:after {
					content: "";
					display: block;
					padding-bottom: 100%;
				}
			}
		}

		.person__right {
			h3 {
				margin-bottom: $spacing-tiny;
				font-family: "Absolution-0";
				font-size: 18px !important;

				@media (min-width: $breakpoint-desktop) {
					font-size: 24px !important;
				}
			}

			p {
				margin-top: 0;
				margin-bottom: spacing-tiny;
			}
		}
	}
}
