.hero {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 500px;
  margin-bottom: 200px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 50px;
  text-align: center;
  background-color: $green;
  position: relative;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 100px;
  }

  @media (min-width: $breakpoint-tablet) {
    &:not(.with-logo) {
      padding-left: 23%;
      padding-right: 23%;
      padding-bottom: 50px;
    }
  }

  &.home {
    padding-top: 150px;
    margin-bottom: 0;

    .button {
      color: $black;
      border-color: $black;

      &:hover {
        color: $white;
      }
    }
    .hero_home_text {
      color: $black;
    }
    .hero_title {
      color: $black;
      font-size: 28px;

      @media (min-width: $breakpoint-desktop) {
        font-size: 40px;
      }
    }
  }

  &.with-logo {
    display: flex;
    flex: 2;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 300px !important;
    margin-bottom: 50px !important;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 35px;
    padding-top: 50px;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 100px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .hero_home_text_container {
    min-height: 300px;
    margin-bottom: 48px;
  }

  .button {
    color: $white;
    border-color: $white;
    margin: 0 !important;
  }

  .logo-wrap {
    flex: 1;
    transform: scale(3.5);
    height: 100%;
    margin-left: 80px;
    object-fit: contain;
    @media (max-width: $breakpoint-desktop) {
      width: auto;
      transform: scale(2);
      margin-left: 0;
      margin-top: 100px;
    }

    @media (max-width: $breakpoint-tablet) {
    }
  }
}

.hero-arrow-container {
  position: relative;
  height: 150px;

  @media (min-width: $breakpoint-tablet) {
    height: 200px;
  }

  .hero-arrow-position {
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translate(-50%, 0);

    @media (min-width: $breakpoint-tablet) {
      left: 10%;
      transform: translate(0, 0);
    }
  }

  .hero-arrow {
    width: auto;
    height: 150px;

    @media (min-width: $breakpoint-tablet) {
      height: 240px;
    }
  }
}

.hero_animation_container {
  width: 105%;
  position: absolute !important;
  display: block;
  justify-content: center;
  align-items: center;
  left: -10;
  margin-top: 100px;
  transform: translate(-50%, 0);
  transform: scale(0.85);
  scroll-snap-align: center;
  @media (max-width: $breakpoint-desktop) {
    transform: scale(0.95);
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }

  .hero_animation_parallax {
    scroll-snap-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 110px;
  }

  .hero_animation {
    &.left {
      width: 50%;
    }
    &.right {
      width: 50%;
    }
  }
}

.hero_animation_container_mobile {
  position: absolute !important;
  width: 105%;

  scroll-snap-align: center;

  @media (min-width: $breakpoint-mobile) {
    display: none;
  }

  .hero_animation_parallax {
    scroll-snap-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 110px;
  }

  .hero_animation {
    &.left {
      margin-top: 500px;
      margin-right: 50px;
      width: 50%;
    }
    &.right {
      margin-bottom: 330px;
      margin-right: 30px;
      width: 50%;
    }
  }
}

.hero_left {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    align-items: center;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.hero_home_text {
  font-size: 28px;
  font-family: "Absolution-0";
  text-align: center;
  color: $white;
  line-height: normal;

  @media (min-width: $breakpoint-desktop) {
    font-size: 40px;
  }
}
.hero_title {
  font-size: $title-font-size;
  font-family: "Absolution";
  text-align: center;
  color: $white;

  &.black {
    color: $black;
  }

  &.white {
    color: $white;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: $smaller-title;
  }

  &.archive {
    text-align: left;
    font-size: $smaller-title;
    font-family: "Absolution-0";
    width: auto;

    @media (max-width: $breakpoint-tablet) {
      font-size: $tiny-title;
    }
  }
}

.hero_text {
  font-size: $body-font-size-desktop;
  text-align: center;
  color: $white;
  z-index: 2;
  padding-left: 20px;
  padding-right: 20px;

  &.bold {
    font-weight: 600;

    &.stories {
      margin-top: 20px;
      margin-bottom: 20px !important;
    }
  }

  &:nth-child(2) {
    margin-bottom: 24px;
  }

  &.with-logo {
    padding-left: 0;
    text-align: left;
    line-height: 1.5em;
    margin-top: 20px;
    @media (max-width: $breakpoint-tablet) {
      text-align: center;
    }
    @media (max-width: $breakpoint-mobile) {
      text-align: left;
      align-items: flex-start;
    }
  }

  &.stories {
    margin-top: 15px;
    width: 70%;
    text-align: left;
    margin-top: -20px;
    margin-bottom: 48px;

    @media (max-width: $breakpoint-desktop) {
      width: 100%;
    }
  }
}

.hero_archive {
  display: flex;
  width: 90%;
  flex-direction: row;
  min-height: auto;
  margin-bottom: 100px;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 0px;
    flex-direction: column;
    align-items: center;
    max-height: unset;
  }
  &.stories {
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 50px;

    @media (max-width: $breakpoint-desktop) {
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
    }
  }

  .hero_title {
    padding: 20px;
  }

  &.highlight {
    .hero_title {
      font-family: "Absolution-0";
      font-size: 32px;
      text-align: left;
      width: auto;
    }
  }
}

.hero_column_archive {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  @media (max-width: $breakpoint-desktop) {
    width: 100%;
  }

  &.left {
    &.stories {
      flex: 3;
      justify-content: center;
      @media (max-width: $breakpoint-mobile) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.right {
    @media (max-width: $breakpoint-tablet) {
      margin-left: 0;
      margin-top: 20px;
      height: 400px;
    }
    &.stories {
      flex: 1;
      transform: scale(1.25);
      justify-content: center;
      align-items: center;
      margin-right: 50px;

      @media (max-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }

  &:nth-child(1) {
    padding-left: 30px;
    padding-top: 50px;
    @media (max-width: $breakpoint-desktop) {
      padding-right: 30px;
      padding-bottom: 30px;
    }
    @media (max-width: $breakpoint-tablet) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.stories_search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
  padding-left: 20px;

  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 0px;
    margin-top: -10px;
  }

  &_text {
    margin-left: 10px;
    margin-top: -3px;
  }

  input[type="text"] {
    position: relative;
    color: $black;
    font-size: $info-text;
    font-family: "Absolution-0";
    font-weight: 300;
    text-decoration: none;
    padding-top: 3px;
    padding-left: 28px;
    padding-bottom: 10px;
    margin-left: -20px;
    margin-top: -5px;
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    border-bottom: 0.5px solid black;

    &::placeholder {
      color: $black;
      font-size: $info-text;
    }

    &:focus {
      outline: none;
    }
  }
}

.hero_archive_stories_buttons {
  margin-top: 10px;
  padding-left: 20px;
  z-index: 10;
  @media (max-width: $breakpoint-mobile) {
    margin-top: 0;
  }
  .stories_button {
    border: 1px $black solid;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: $button-font-size;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: "Absolution-0";
    background-color: transparent;
    width: auto;
    cursor: pointer;
    display: inline-block;
    text-align: left;
    @media (max-width: $breakpoint-mobile) {
      padding-left: 16px;
      padding-right: 16px;
    }
    &.selected {
      color: $white !important;
      background-color: $black;
    }
  }
}

.hero_read-more {
  margin-top: calc(144px - 24px - 32px);
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 0;
  }
}
