body.maintenance {
  .nav {
    display: none;
  }
}

.header {
  margin-top: 215px;
}

.nav {
  display: flex;
  background-color: transparent;
  z-index: 12;
  overflow: visible;
  padding: $spacing-small;
  transition: all 0.4s ease-in-out 0s;
  animation-duration: 0.5s;
  position: fixed;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 0px;
  transform: translateY(0);

  &.hidden {
    @media (max-width: $breakpoint-tablet) {
      transition: all 0.4s ease-in-out 0s;
      transform: translateY(-900%);
    }
  }

  &.is-active {
    transition: height 0.35s ease-in-out 0s;
    background-color: $white;
    min-height: 100vh;
    align-items: flex-start;
    padding-top: 12vh;
    overflow: scroll;

    ul.menu {
      .menu-bg {
        @media (max-width: $breakpoint-desktop) {
          pointer-events: all;
          transition: all 0.35s ease-in-out 0s;
        }
      }

      .submenu {
        z-index: -10;
        opacity: 0;
        position: relative;
        margin-left: 40px;
        overflow: hidden;
        transform: translateY(-100%);
        transition: all 0.4s ease-in-out;
        animation-duration: 0.5s;
        &.open {
          opacity: 1;
          transform: translateY(0);
          transition: all 0.4s ease-in-out;
          animation-duration: 0.5s;
        }
      }

      .menu-item {
        opacity: 0;
        pointer-events: all;
        transition: opacity 1s ease-in-out 0s;

        // @DEPRICATED
        // transition: all 0.35s ease-in-out;
        // transform: translateY(0) !important;
        // $total-items: 30;
        // @for $i from 1 through $total-items {
        // 	&:nth-child(#{$i}) {
        // 		@media (max-width: $breakpoint-desktop) {
        // 			transition-delay: 0.1s * $i;
        // 		}
        // 		@media (min-width: $breakpoint-desktop) {
        // 			transition-delay: 0.175s * $i;
        // 		}
        // 	}
        // }

        .menu-item__link {
          pointer-events: all;
          @media (max-width: $breakpoint-mobile) {
            line-height: 1em;
            margin-bottom: 0.5em;
          }
        }

        &.is-active {
          opacity: 1;
        }
      }
    }
  }

  .logo-wrap {
    display: block;
    z-index: 1111;
    transform: scale(150%);
    position: absolute;
    left: 100px;
    top: 70px;

    &.desktop {
      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    &.mobile {
      @media (max-width: 350px) {
        transform: scale(100%);
        left: 5px;
        top: 60px;
      }

      @media (max-width: $breakpoint-tablet) {
        left: 11%;
        top: 60px;
      }

      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .logo {
      &.mobile {
        transform: scale(0.8);
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .menu-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    padding: 20px;
    z-index: 20;
    @media (min-width: $breakpoint-desktop) {
      margin-left: -10%;
    }
    @media (max-width: $breakpoint-mobile) {
      margin-left: 0;
      width: 100%;
    }
  }

  // @DEPRICATED
  .menu-bottom {
    @media (min-width: $breakpoint-desktop) {
      position: absolute;
      top: calc(100% + 96px);

      .menu-item {
        transform: translateY(-20px) !important;
      }
    }
  }

  ul.menu {
    margin: 0;
    padding: 0;
    pointer-events: none;
    width: calc(100% - 32px);

    @media (max-width: $breakpoint-desktop) {
      width: 100%;
      left: 0;
      top: 72px;
      bottom: unset;
      padding: 0 $spacing-small;

      .menu-bg {
        width: 100%;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: -1px;
        height: 0;
        background-color: white;
        transition: height 0.35s ease-in-out 0.5s;
        border-bottom: 1px solid $black;
      }
    }

    @media (min-width: $breakpoint-desktop) {
      bottom: 54%;

      .menu-bg {
        display: none;
      }
    }

    .menu-item {
      list-style-type: none;
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.35s ease-in-out 0s;
      pointer-events: none;
      margin-bottom: 12px;

      &.is-submenu-active {
        ul.submenu {
          max-height: 800px;

          li.submenu-item {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
            $total-items: 30;
            @for $i from 1 through $total-items {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i;
              }
            }
          }
        }

        .menu-item-wrap {
          button {
            transform: rotate(45deg);
          }
        }
      }

      .menu-item-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          transform: rotate(0deg);
          transition: all 0.35s ease-in-out;
          border: none;
          background-color: transparent;
          font-size: 28px;
          font-weight: normal;
          padding: 4px;
          height: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          margin-top: 7%;
        }
      }

      .menu-item__link {
        color: $black;
        pointer-events: none;
        font-size: 48px;
        line-height: 72px;
        font-family: "Absolution-0";
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;
        width: 100%;

        @media (max-width: $breakpoint-mobile) {
          width: fit-content;
          font-size: $small-title;
        }

        &.bold {
          font-weight: 800;
          border-bottom: 5px solid black;
          width: max-content;
          @media (max-width: $breakpoint-tablet) {
            width: fit-content;
          }
          @media (max-width: $breakpoint-mobile) {
            font-weight: 600;
          }
          &.sub {
            line-height: 48px;
            font-size: 32px;
          }
        }

        &.sub {
          line-height: 48px;
          font-size: 32px;
        }
      }

      // ul.submenu {
      //   //border: 1px dashed red;
      //   padding-left: $spacing-small;
      //   max-height: 0;
      //   transition: all 0.45s ease-in-out;
      //   overflow: hidden;

      //   li.submenu-item {
      //     //border: 1px dashed green;
      //     margin-bottom: $spacing-tiny;
      //     opacity: 0;
      //     pointer-events: none;
      //     transform: translateY(20px);
      //     transition: all 0.35s ease-in-out;

      //     &:first-of-type {
      //       margin-top: $spacing-tiny;
      //     }

      //     &:last-of-type {
      //       margin-bottom: 0;
      //     }

      //     a.submenu-item__link {
      //       font-family: "Finlandica";
      //     }
      //   }
      // }
    }
  }

  .menu-icon-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 30;

    @media (max-width: $breakpoint-tablet) {
      right: 10px;
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      transition: all 0.35s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .hamburger-react {
    border-radius: 100%;
    box-shadow: inset 0px 0px 0px 3px $black;
    position: relative;
    box-sizing: border-box;

    @media (max-width: $breakpoint-mobile) {
      transform: scale(50%);
    }

    div {
      height: 3px !important;
    }
  }
}

.nav-overlay {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  z-index: -1;
  transition: all 0.35s ease-in-out;

  @media (min-width: $breakpoint-desktop) {
    width: calc(100% - 229px);
    left: 230px;
    transition-delay: 0s;
  }

  &.is-active {
    background-color: transparent;
    pointer-events: all;
    z-index: 111;

    @media (min-width: $breakpoint-desktop) {
      transition-delay: 0.3s;
    }
  }
}

.language-switcher {
  margin-top: 150px;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }

  &.is-active {
    @media (max-width: $breakpoint-tablet) {
      display: unset;
      margin-top: 30px;
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    transition: all 0.35s ease-in-out;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    font-family: "Finlandica";
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    @media (min-width: $breakpoint-desktop) {
      font-size: 18px;
      padding: $spacing-tiny / 1.5;
    }

    &.disabled {
      color: $black;
      box-shadow: inset 0px -3px 0px 0px $black;
      cursor: default;
      font-weight: 700;
    }
  }
}

.straight-to-content {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}
