.newsletter {
  &__modal {
    opacity: 0;
    position: fixed;
    transition: all 0.5s ease-in-out;
    background-color: $white;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(calc(-50% + 20px));
    max-width: calc(100% - 32px);
    padding: $spacing-small;
    display: flex;
    flex-direction: column;
    border: 1px solid $black;
    pointer-events: none;

    @media (max-width: $breakpoint-desktop) {
      width: 100%;
    }

    &.is-active {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%);
      pointer-events: all;
      z-index: 111;
      background-color: $white;
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $spacing-small;
    }
  }

  &__close {
    border: none;
    padding: 0;
    background-color: transparent;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    margin-left: $spacing-small;
  }

  &__overlay {
    position: fixed;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: none;
    z-index: -1;
    left: 0;
    top: 0;

    &.is-active {
      pointer-events: all;
      display: block;
      z-index: 1;
    }
  }

  &__form {
    width: 100%;

    .required-info {
      margin-bottom: 0;
      margin-top: $spacing-small;
      color: $grey;
    }

    button {
      margin-bottom: 0;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: $spacing-small;

      label {
        font-size: 16px;
        font-family: "Finlandica";
        margin-bottom: 4px;
        opacity: 0;
        position: absolute;
        pointer-events: none;
      }

      input[type="text"],
      input[type="email"] {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $black;
        padding: 4px 0;
        font-family: "Finlandica";
        font-size: 16px;
        border-radius: 0;
      }
    }

    select {
      width: 100%;
      border-radius: 0;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $black;
      padding: 4px 0;
      font-family: "Finlandica";
      font-size: 16px;
      cursor: pointer;

      &.placeholder {
        color: $grey;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }
    }

    .select-wrap {
      position: relative;

      &:after {
        content: "";
        width: $spacing-tiny;
        height: $spacing-tiny;
        right: 5px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        background-color: transparent;
        border-right: 2px solid $black;
        border-bottom: 2px solid $black;
        pointer-events: none;
        z-index: 1111;
      }
    }

    .form-checkbox {
      position: relative;
      padding-left: 32px;
      margin-bottom: $spacing-small;

      &:focus-within {
        outline: 1px dotted #212121 !important;
        outline: 5px auto -webkit-focus-ring-color !important;
      }

      label {
        font-size: 16px;
        font-family: "Finlandica";
        cursor: pointer;
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        position: absolute;
        left: 0;

        &:before {
          content: "";
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          width: $spacing-small;
          height: $spacing-small;
          background-color: $white;
          border: 1px solid $black;
          cursor: pointer;
        }

        &:after {
          transition: all 0.35s ease-in-out;
          content: "";
          width: 5px;
          height: $spacing-tiny;
          left: 5px;
          top: calc(50% + 5px);
          transform: translateY(-50%) rotate(45deg);
          position: absolute;
          background-color: transparent;
          border-right: 2px solid $black;
          border-bottom: 2px solid $black;
          opacity: 0;
          pointer-events: none;
          cursor: pointer;
        }

        &:checked {
          &:after {
            opacity: 1;
            top: calc(50% - 1px);
          }
        }
      }
    }
  }
}
