.search__modal {
  position: absolute;
  background-color: $white;
  height: calc(100vh - 112px);
  z-index: 0;
  top: 36px;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  width: 100%;
  padding-top: $spacing-small;

  @media (max-width: $breakpoint-desktop) {
    padding-bottom: 128px;
  }

  @media (min-width: $breakpoint-desktop) {
    position: fixed;
    padding-top: 0;
    height: 100vh;
    top: 0;
    width: calc(100vw - 230px);
    left: 230px;
  }

  &.is-active {
    z-index: 111111;
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
    overflow-y: scroll;
  }

  .header {
    h1 {
      span {
        font-family: "Absolution-0";
      }
    }

    @media (max-width: $breakpoint-desktop) {
      padding-top: 0;
    }
  }

  .show-more {
    margin: $spacing-small 0 $spacing-small 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.search__input-wrap {
  position: relative;
  pointer-events: none;
  z-index: 1111;
  margin-bottom: $spacing-small;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (min-width: $breakpoint-desktop) {
    position: relative;
    margin-bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    background-color: $black;
    width: 100%;
    bottom: -6px;
    left: 0;
    opacity: 0;
    transform: translateY(5px);
    transition: all 0.35s ease-in-out;
  }

  &:focus-within {
    &:after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.mobile {
    width: 100%;
    max-width: 200px;
    @media (min-width: $breakpoint-desktop) {
      display: none;
    }
  }

  &.desktop {
    @media (max-width: $breakpoint-desktop) {
      display: none;
    }
  }

  label {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    overflow: hidden;
  }

  .search__input {
    position: relative;
    color: $black;
    font-size: 24px;
    font-family: "Absolution-2";
    font-weight: 300;
    text-decoration: none;
    padding-left: 28px;
    padding-bottom: 10px;
    margin-left: -20px;
    margin-top: -5px;
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;

    &::placeholder {
      color: $black;
    }

    &:focus {
      cursor: text;
    }

    @media (min-width: $breakpoint-desktop) {
      width: calc(100% - #{$spacing-medium});
    }
  }

  .search__clear {
    right: 0;
    position: absolute;
    border: none;
    padding: 0;
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    transition: all 0.35s ease-in-out;
    transform: translateY(5px);
    top: -1px;
    cursor: pointer;

    &.is-active {
      transform: translateY(0);
      pointer-events: all;
      opacity: 1;
    }
  }
}

.search-results-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: relative;

  &__item {
    position: relative;
    @include make-col-ready();
    @include make-col(12);
    margin-bottom: $spacing-medium;
    display: flex;
    flex-direction: row;
    animation: fade 0.5s ease-in-out;

    @keyframes fade {
      from {
        transform: translateY(20px);
        opacity: 0;
      }
      to {
        transform: translateY(0px);
        opacity: 1;
      }
    }

    @media (min-width: $breakpoint-desktop) {
      margin-bottom: $spacing-small;
    }

    .item-image {
      width: 20%;
      padding-right: $spacing-small;

      @media (min-width: $breakpoint-desktop) {
        width: 20%;
      }

      div {
        width: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $bright-grey;

        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }

    .item-content {
      width: 80%;

      @media (min-width: $breakpoint-desktop) {
        padding-top: $spacing-tiny;
      }

      .meta p {
        display: inline-block;
        margin-bottom: $spacing-small;

        &:not(:last-of-type) {
          margin-right: $spacing-tiny;
        }
      }

      h2 {
        margin-bottom: $spacing-small;
      }
    }
  }
}
