.pagination {
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: $spacing-small;

  a,
  div {
    display: inline-block;
    background-color: $white;
    margin-right: $spacing-tiny;
    font-family: "Absolution-2";
    font-size: 28px;
    transition: all 0.35s ease-in-out;
    font-weight: normal;
    text-decoration: none;
    color: $black;

    @media (min-width: $breakpoint-desktop) {
      font-size: 42px;
    }

    &.is-active {
      @media (min-width: $breakpoint-desktop) {
        border-bottom: 1px solid $black;
      }
    }

    &.is-visible {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
      transition: all 0.35s ease-in-out;
    }

    &.is-not-visible {
      pointer-events: none;
      opacity: 0;
      transform: translateY(20px);
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }

    &.dots,
    &.first,
    &.last {
      @media (max-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }

  a {
    padding: calc($spacing-tiny / 2);

    @media (max-width: $breakpoint-desktop) {
      padding: $spacing-tiny;
    }
  }
}
