.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .error__container {
    width: 100%;
    max-width: 680px;
    text-align: center;
    animation: 0.5s ease-in-out fade;

    @keyframes fade {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    .error__icon {
      margin-bottom: $spacing-small;
      position: relative;

      h1 {
        text-align: center;
        font-size: 32px;

        @media (min-width: $breakpoint-desktop) {
          font-size: 48px;
        }
      }
    }

    .error__content {
      h1 {
        text-align: center;
        margin-bottom: $spacing-small;
      }

      p {
        margin-bottom: $spacing-small + $spacing-tiny;
      }
    }
  }
}

.home {
  padding-bottom: $spacing-medium;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 72px;
  padding-right: 72px;

  @media (max-width: $breakpoint-tablet) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.about {
  padding-bottom: $spacing-medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 72px;
  padding-right: 72px;

  @media (max-width: $breakpoint-desktop) {
    margin-top: 250px;
  }

  @media (min-width: $breakpoint-desktop) {
    margin-top: 250px;
  }

  @media (max-width: $breakpoint-tablet) {
    margin-top: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.archive {
  padding-bottom: $spacing-medium;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 72px;
  padding-right: 72px;

  @media (max-width: $breakpoint-desktop) {
    margin-top: 250px;
  }

  @media (min-width: $breakpoint-desktop) {
    margin-top: 250px;
  }

  @media (max-width: $breakpoint-tablet) {
    margin-top: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__image {
    margin-bottom: $spacing-large + $spacing-small;
    margin-left: $spacing-small;
    width: calc(100% - #{$spacing-medium});

    @media (min-width: $breakpoint-desktop) {
      padding: 0 60px;
    }
  }
}

.document {
  min-height: 100vh;

  @media (min-width: $breakpoint-desktop) {
    position: relative;
    z-index: 1;
    background-color: $white;
  }

  .page {
    @media (max-width: $breakpoint-desktop) {
      margin-top: 250px;
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width: $breakpoint-desktop) {
      margin-top: 250px;
      padding-left: 72px;
      padding-right: 72px;
    }

    @media (max-width: $breakpoint-tablet) {
      margin-top: 120px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .document__body {
    .document__image-wrap {
      margin-bottom: $spacing-medium;

      .document__image {
        margin-left: $spacing-small;
        width: calc(100% - #{$spacing-medium});
        margin-bottom: $spacing-small;

        @media (min-width: $breakpoint-desktop) {
          padding: 0 60px;
        }

        &--article {
          margin-bottom: $spacing-small;
        }
      }

      .document__image-caption {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $spacing-small !important;
        @include make-container($gutter: 0);
        max-width: 1200px;
        font-style: italic;

        @media (min-width: $breakpoint-desktop) {
          padding: 0 calc(60px + 16px) !important;
        }

        p {
          @include make-col-ready();
          @include make-col(12);
          padding: 0 !important;

          @media (min-width: $breakpoint-desktop) {
            @include make-col(8);
          }
        }
      }
    }

    .document__content {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      @include make-container($gutter: 0);
      max-width: 1200px;

      @media (min-width: $breakpoint-desktop) {
        padding: 0 60px;
      }

      &.event-content {
        @media (max-width: $breakpoint-desktop) {
          .document__slices {
            order: 2;
          }

          .document__meta {
            order: 1;
          }
        }
      }

      .document__slices {
        @include make-col-ready();
        @include make-col(12);

        @media (min-width: $breakpoint-desktop) {
          @include make-col(8);
        }

        p,
        h2,
        h3,
        h4,
        .button {
          margin-bottom: $spacing-small;
          max-width: $breakpoint-desktop;
        }

        .button {
          margin-top: $spacing-tiny;
        }

        h2 {
          margin-top: $spacing-medium;
        }

        .button-container {
          display: block;
        }

        .image-with-caption,
        .embed-container {
          margin-top: $spacing-small;
          margin-bottom: $spacing-medium;
          max-width: $breakpoint-desktop;
        }
      }

      .document__meta {
        margin-top: $spacing-large;
        @include make-col-ready();
        @include make-col(12);

        @media (min-width: $breakpoint-desktop) {
          @include make-col(4);
          margin-top: 0;
        }

        .meta-wrap {
          margin-bottom: $spacing-medium - $spacing-tiny;
          display: flex;
          flex-direction: column;
        }

        p:not(.author) {
          margin-bottom: $spacing-tiny;
        }

        .share-text {
          margin-top: $spacing-small;
        }

        .person {
          margin-bottom: $spacing-medium;

          p:not(.title) {
            font-size: 22px;
            font-family: "Absolution-0";

            @media (min-width: $breakpoint-desktop) {
              font-size: 32px;
            }
          }
        }
      }
    }
  }
}

.tags {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .tag {
    display: inline-block;

    a,
    button {
      color: $grey;
      margin-bottom: $spacing-tiny !important;
      text-decoration: none;
      margin-right: $spacing-tiny;
      border: 1px solid $grey;
      padding: 4px $spacing-tiny;
      transition: all 0.35s ease-in-out;
      background-color: transparent;
      font-size: 16px;

      &:hover {
        background-color: $grey;
        color: $white;
        cursor: pointer;
      }
    }
  }
}

.updated {
  display: inline-block;
}

.title_h1 {
  text-align: left;
  font-size: $title-font-size;
  margin-left: 100px;
}
