.columns {
	margin-bottom: $spacing-medium + $spacing-small;

	&.grey {
		margin-bottom: 0;
		padding: $spacing-medium + $spacing-small 0;
		background-color: $bright-grey;
	}

	.columns-grid {
		display: flex;
		flex-direction: column;

		@media (min-width: $breakpoint-desktop) {
			flex-direction: row;
		}

		&__item {
			margin-left: auto;
			margin-right: auto;
			padding: 0 $spacing-small;
			display: flex;
			flex-direction: column;

			@media (max-width: 1391px) {
				width: 100%;

				&:not(:last-of-type) {
					margin-bottom: $spacing-small;
				}
			}

			@media (min-width: $breakpoint-desktop) {
				width: 680px;
				max-width: stretch;
			}

			&.order-2 {
				order: 2;
			}

			p,
			h2,
			h3,
			h4,
			h5,
			h6,
			.button {
				margin-bottom: $spacing-small;
			}

			.small {
				font-size: 12px;
			}
		}
	}
}

.press-releases {
	margin-bottom: $spacing-medium + $spacing-small;

	h2 {
		width: 100%;
		max-width: 680px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $spacing-small;
		margin-bottom: $spacing-medium;
	}

	.press-releases-grid {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;

		@media (min-width: $breakpoint-desktop) {
			flex-direction: row;
		}

		&__item {
			margin-left: auto;
			margin-right: auto;
			padding: 0 $spacing-small;
			position: relative;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}

			@media (min-width: $breakpoint-desktop) {
				width: 680px;
				max-width: stretch;
			}

			p.updated {
				margin-bottom: $spacing-tiny;
			}

			p {
				margin-bottom: $spacing-small;
			}

			h3,
			h4,
			h5,
			h6 {
				font-family: "Finlandica";
				margin-bottom: $spacing-tiny;
			}
		}
	}
}

.parallax-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.parallax-arrow {
	@media (max-width: 1391px) {
	}

	@media (min-width: $breakpoint-desktop) {
	}

	.react-parallax {
		overflow: visible !important;
	}

	img {
		background-color: transparent;
		margin-bottom: -50px;
	}
}

.quote {
	position: relative;
	padding-left: 56px;
	padding-top: $spacing-small;
	padding-bottom: $spacing-small;

	&::before {
		content: '"';
		position: absolute;
		left: 0;
		top: 2px;
		font-size: 80px;
		font-family: "Absolution";

		@media (min-width: $breakpoint-desktop) {
			top: 4px;
		}
	}

	p:not(.quotee) {
		font-family: "Absolution-0";
		font-size: 22px;

		@media (min-width: $breakpoint-desktop) {
			font-size: 32px;
		}
	}

	p.quotee {
		font-style: italic;
	}
}

.lead {
	p,
	a,
	li {
		font-style: italic;
		font-size: 18px;
		@media (min-width: $breakpoint-desktop) {
			font-size: 20px;
		}
	}
}

span.lead {
	font-style: italic;
	font-size: 18px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 20px;
	}
}

span.finlandica {
	font-style: normal;
	font-size: 22px;
	font-family: "Finlandica";

	@media (min-width: $breakpoint-desktop) {
		font-size: 32px;
	}
}

.highlight-section {
	position: relative;
	padding: 0 $spacing-small;
	margin-bottom: 48px;

	@media (min-width: $breakpoint-desktop) {
		margin-bottom: -60px;
	}

	.react-parallax {
		overflow: visible !important;
	}

	&--alignment-right {
		.highlight-section__media {
			align-items: flex-start;
		}

		.parallax-icon-wrap {
			@media (min-width: $breakpoint-desktop) {
				left: -60px;
			}
		}

		.highlight-section__video-controls {
			@media (min-width: $breakpoint-desktop) {
				flex-direction: row;
				right: unset;
				width: 60%;

				.volume-up {
					margin-right: $spacing-small;
				}
			}
		}

		.highlight-section__title-wrap {
			@media (min-width: $breakpoint-desktop) {
				transform: translateX(0) translateY(60px);
				right: -60px;
			}

			.react-parallax {
				@media (min-width: $breakpoint-desktop) {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
				}
			}
		}

		.highlight-section__content-wrap {
			@media (min-width: $breakpoint-desktop) {
				transform: translateX(0) translateY(-60px);
				right: -60px;
			}

			.react-parallax {
				@media (min-width: $breakpoint-desktop) {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
				}
			}
		}
	}

	.container {
		padding: 0;

		@media (min-width: $breakpoint-desktop) {
			padding: 0 120px;
		}
	}

	&__media {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.parallax-icon-wrap {
		position: absolute !important;
		right: 0;
		z-index: 11;
		pointer-events: none;

		@media (min-width: $breakpoint-desktop) {
			right: -60px;
		}
	}

	&__parallax-icon {
		width: 100px;
		height: 100px;
		background-size: cover;
		background-repeat: no-repeat;
		pointer-events: none;

		@media (min-width: $breakpoint-desktop) {
			width: 200px;
			height: 200px;
		}

		&.sustainable-transition {
			background-image: url("../../icons/icon-arrow.svg");
		}

		&.equality-and-diversity {
			background-image: url("../../icons/icon-roundabout.svg");
		}

		&.renewal-and-recovery {
			background-image: url("../../icons/icon-circle.svg");
		}

		&.locality-and-heritage {
			background-image: url("../../icons/icon-beam.svg");
		}

		&.learning-and-imagination {
			background-image: url("../../icons/icon-lightbulb.svg");
		}

		&.wellness-and-healthcare {
			background-image: url("../../icons/icon-cross.svg");
		}
	}

	&__video-controls {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: $spacing-small;
		width: 100%;

		@media (min-width: $breakpoint-desktop) {
			position: absolute;
			flex-direction: row-reverse;
			right: 60px;
			width: calc(60% - 60px);
		}

		span {
			display: flex;
			flex-direction: row;
		}

		button {
			cursor: pointer;
			border: none;
			background-color: transparent;
			font-size: 18px;
			font-family: "Absolution-0";
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 0;
			padding: 0;
			color: $black;

			&:hover {
				ion-icon {
					transform: scale(1.2);
				}
			}

			ion-icon {
				transition: transform 0.35s ease-in-out;
			}

			@media (min-width: $breakpoint-desktop) {
				margin-left: $spacing-small;
				font-size: 24px;
			}

			svg {
				fill: $black;

				path {
					fill: $black;
				}
			}

			&.play,
			&.pause {
				ion-icon {
					margin-right: $spacing-tiny;
				}
			}

			&.volume-up {
				@media (max-width: $breakpoint-desktop) {
					margin-left: $spacing-small;
				}
			}
		}
	}

	&__image {
		width: 100%;

		@media (min-width: $breakpoint-desktop) {
			width: calc(100% - 60px);
		}
	}

	&__video-wrap {
		position: relative;

		.volume-up,
		.volume-down {
			opacity: 0;
			pointer-events: none;
		}

		.pause {
			display: none;
		}

		&.is-playing {
			.play {
				display: none;
			}

			.pause {
				display: flex;
			}

			.volume-up,
			.volume-down {
				opacity: 1;
				pointer-events: all;
			}
		}

		&.no-audio {
			.volume-up,
			.volume-down {
				display: none;
			}
		}
	}

	&__video {
		width: 100%;

		height: auto;
		cursor: pointer;
		transition: filter 0.35s ease-in-out;

		&.is-loading {
			filter: blur(10px);
		}

		@media (min-width: $breakpoint-desktop) {
			width: calc(100% - 60px);
		}
	}

	&__title-wrap {
		width: 100%;
		position: relative;
		pointer-events: none;
		z-index: 11;

		h2 {
			margin-bottom: 0 !important;
			line-height: 1;
		}

		.highlight-section__content {
			padding-top: $spacing-small - 4px;
			padding-bottom: $spacing-small - 4px;
		}

		@media (min-width: $breakpoint-desktop) {
			transform: translateX(-60px) translateY(60px);
		}
	}

	&__content-wrap {
		width: 100%;
		position: relative;
		pointer-events: none;

		@media (min-width: $breakpoint-desktop) {
			transform: translateX(-60px) translateY(-60px);
		}
	}

	&__content {
		position: relative;
		background-color: $white;
		padding: $spacing-small 0 0 0;
		pointer-events: all;

		@media (max-width: $breakpoint-desktop) {
			transform: unset !important;
		}

		@media (min-width: $breakpoint-desktop) {
			width: calc(40% + 60px);
			padding: $spacing-small $spacing-small 0 $spacing-small;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		li {
			margin-bottom: $spacing-small;
		}
	}
}
