.subscribe {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 300px;
  height: auto;
  margin-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: left;
  background-color: $bright-grey;
  .subscribe_text {
    text-align: left;
    font-size: $title-font-size;
    line-height: 1.2em;
    font-family: "Absolution";
    @media (max-width: $breakpoint-mobile) {
      font-size: $small-title;
    }
  }
}

.subscribe_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.subscribe_button {
  color: $black;
  border: 1px $black solid;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: $button-font-size;
  margin-top: 32px;
  font-weight: 500;
}

.subscribe_parallax {
  position: relative;
  top: 100px;
  left: 40px;
}
