.header {
	padding-top: $spacing-medium;

	h1,
	h2,
	h3 {
		font-size: 32px;
		width: 100%;

		@media (min-width: $breakpoint-desktop) {
			font-size: 48px;
		}
	}

	.breadcrumbs {
		list-style-type: none;
		padding: 0;

		.breadcrumbs-item {
			display: inline-block;
			position: relative;
			font-size: 16px;
			margin-bottom: $spacing-tiny;

			a {
				font-size: 16px;
			}

			&:not(:last-child) {
				padding-right: $spacing-small + 2px;

				&:after {
					content: "";
					height: 1px;
					background-color: $black;
					width: $spacing-small;
					position: absolute;
					right: 0;
					bottom: -1px;
				}
			}

			&:last-child {
				font-weight: bold;

				a {
					font-weight: bold;
				}
			}
		}
	}
}

.home-header {
	margin-bottom: $spacing-small;

	@media (min-width: $breakpoint-desktop) {
		border-bottom: 1px solid $black;
	}

	@media (min-width: $breakpoint-desktop) {
		padding: 0 $spacing-small;
	}

	&-grid {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (min-width: $breakpoint-desktop) {
			flex-direction: row;
		}

		&__item {
			width: 100%;

			&:first-of-type {
				padding: $spacing-small;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@media (max-width: $breakpoint-desktop) {
					border-bottom: 1px solid $black;
				}

				@media (min-width: $breakpoint-desktop) {
					width: 25%;
					padding-left: 0;
					border-right: 1px solid $black;
				}
			}

			&:last-of-type {
				padding-right: 0;
				padding-left: 0;

				@media (min-width: $breakpoint-desktop) {
					width: 75%;
				}

				&.theme-grid {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;

					@media (min-width: $breakpoint-desktop) {
						flex-direction: row;
					}

					.theme-grid__item {
						width: 100%;
						padding: $spacing-small;
						display: flex;
						flex-direction: column;
						justify-content: center;

						h2 {
							font-family: "Absolution-0";
							font-size: 22px;

							@media (min-width: $breakpoint-desktop) {
								font-size: 32px;
							}
						}

						@media (max-width: $breakpoint-desktop) {
							border-bottom: 1px solid $black;
						}

						@media (min-width: $breakpoint-desktop) {
							border-right: 1px solid $black;
						}

						&:nth-child(3),
						&:nth-child(6) {
							border-right: none;

							@media (min-width: $breakpoint-desktop) {
								border-bottom: none;
								padding-right: 0;
							}
						}

						&:nth-child(3) {
							@media (max-width: $breakpoint-desktop) {
								border-bottom: 1px solid $black;
							}
						}

						@media (min-width: $breakpoint-desktop) {
							width: 33.3333333333%;
						}
					}
				}
			}
		}
	}

	h1 {
		margin-bottom: $title-margin-bottom;
	}

	p {
		margin-bottom: $title-margin-bottom;
	}

	.button {
		margin-bottom: 0;
	}
}

.theme-header {
	.narrow-grid__item {
		display: flex;
		flex-direction: row;
		margin-bottom: $spacing-large;

		.image {
			width: 15%;
			height: auto;
			flex-shrink: 0;
			margin-right: $spacing-medium;
		}
	}
}
