h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li {
	margin-block-start: 0;
	margin-block-end: 0;

	&.green {
		color: $green;
	}

	&.yellow {
		color: $yellow;
	}

	&.blue {
		color: $blue;
	}

	&.dark-blue {
		color: $dark-blue;
	}

	&.grey {
		color: $grey;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	display: block;
	font-family: "Absolution";
	font-weight: normal;
	color: $black;
}

h1 {
	font-size: 32px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 48px;
	}
}

h2 {
	font-size: 26px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 40px;
	}
}

h3 {
	font-size: 22px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 32px;
	}
}

h4 {
	font-size: 18px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 22px;
	}
}

h5 {
	font-size: 16px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 16px;
	}
}

h6 {
	font-size: 16px;

	@media (min-width: $breakpoint-desktop) {
		font-size: 16px;
	}
}

p {
	display: block;
	line-height: 1.6;
	font-family: "Finlandica";
	color: $black;
	font-size: $body-font-size-mobile;

	@media (min-width: $breakpoint-desktop) {
		font-size: $body-font-size-desktop;
	}

	a {
		display: inline;
		font-size: $body-font-size-mobile;
		font-family: "Finlandica";
		text-decoration: none;
		background-image: linear-gradient(0deg, $blue 0%, $blue 100%);
		background-repeat: no-repeat;
		background-size: 100% 1px;
		background-position: 0 100%;
		transition: all 0.15s ease-in-out;
		color: $blue;
		padding: 1px 1px 0 1px;

		&:hover {
			background-size: 100% 88%;
			color: $white;
		}

		@media (min-width: $breakpoint-desktop) {
			font-size: $body-font-size-desktop;
		}
	}

	li {
		font-family: "Finlandica";
	}
}

li {
	font-family: "Finlandica";
	font-size: $body-font-size-mobile;

	@media (min-width: $breakpoint-desktop) {
		font-size: $body-font-size-desktop;
	}
}

a {
	display: block;
	font-family: "Finlandica";
	text-decoration: none;
	color: $black;
	font-size: $body-font-size-mobile;

	@media (min-width: $breakpoint-desktop) {
		font-size: $body-font-size-desktop;
	}
}

.typewriter-container {
	position: relative;
	text-align: inherit;
	margin-bottom: inherit;

	.animated-text {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		color: $black;

		&.white {
			color: $white;
		}

		&.black {
			color: $black;
		}
	}

	.screen-reader-text {
		opacity: 0;
		z-index: 2;
	}
}
