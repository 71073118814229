button {
	font-size: 18px;
	font-family: "Finlandica";
}

.button {
	position: relative;
	display: inline-block;
	margin-bottom: $button-margin-bottom;
	background-color: transparent;
	color: $black;
	padding: $spacing-tiny 24px;
	text-decoration: none;
	line-height: 1.2;
	margin-right: $spacing-small;
	font-family: "Absolution-0";
	font-size: 20px;
	border: 3px solid $black;
	transition: all 0.35s ease-in-out;
	cursor: pointer;
	border-radius: 0;
	-webkit-appearance: none;

	@media (min-width: $breakpoint-desktop) {
		font-size: 28px;
	}

	&:hover {
		background-color: $black;
		color: $white;
	}

	&.text {
		border: none;
		padding: 0 16px 0 0;
		font-size: 20px;

		&:hover {
			background-color: transparent;
			color: $black;

			&:after {
				right: -16px;
			}
		}

		&:after {
			transition: all 0.35s ease-in-out;
			content: "";
			position: absolute;
			right: 0;
			width: 8px;
			height: 8px;
			top: 50%;
			border-top: 3px solid $black;
			border-right: 3px solid $black;
			transform: translateY(-50%) rotate(45deg);
			background-image: url("../../icons/arrow.svg");
		}
	}
}
