.about_card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  aspect-ratio: 1/1;
  @media (max-width: $breakpoint-tablet) {
    aspect-ratio: unset;
  }
}

.about_text {
  font-family: "Finlandica";
  font-size: $body-font-size-desktop;
  line-height: 1.5em;

  &.info {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &.contacts {
    margin-bottom: 10px;
  }
}

.about_title {
  font-family: "Absolution";
  font-size: $small-title;
  line-height: 40px;
  margin-bottom: 30px;
  min-height: 80px;
  @media (max-width: $breakpoint-tablet) {
    min-height: unset;
  }
}
